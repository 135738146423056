<template>
  <!--编辑服务商-->
  <div style="padding: 20px;">
    <div class="title">
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <div>
          <span>企业信息</span>
        </div>
        <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
      </div>
    </div>

    <el-form ref="form" :model="form" :rules="rules" label-width="160px">
      <!--认证信息-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 20px;font-weight: 700;color: #202033;margin-right: 20px;">认证信息</span>
          <img v-if="form.approve_status == 1" src="@/assets/images/home/yunli/yirenzheng.png" alt="" style="width: 96px;height: 31px;">
        </div>
        <el-row :gutter="15" style="margin-top: 20px;">
          <el-col :span="4">
            <div class="upload_bg_yingyezhizhao">
              <el-upload
                  disabled
                  :action="action"
                  :headers="headers"
                  :on-success="successUpload"
                  :limit="1"
                  :file-list="fileList"
                  :class="{hide_box: businessLicenseupload_btn}"
                  :on-change="businessLicensechange"
                  list-type="picture-card"
                  :on-preview="businessLicensePreview"
                  :on-remove="businessLicensehandleRemove">
                <!--<i class="el-icon-plus"></i>-->
                <img style="margin-top: 60px;" src="@/assets/images/home/upload_anniu.png" alt="">
                <div style="font-size: 16px;font-weight: 500;color: #ff9b05;margin-top: 15px;">上传营业执照</div>
              </el-upload>
              <el-dialog :visible.sync="businessLicensedialogVisible">
                <img width="100%" :src="businessLicensedialogImageUrl" alt="">
              </el-dialog>
            </div>
          </el-col>
          <el-col :span="20">
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="企业名称" prop="company_name">
                  <el-input
                      clearable
                      :disabled="true"
                      v-model="form.company_name"
                      placeholder="请输入企业名称"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="信用代码" prop="credit_code">
                  <el-input
                      clearable
                      :disabled="true"
                      v-model="form.credit_code"
                      placeholder="请输入信用代码"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="注册日期" prop="registration_date">
                  <el-date-picker
                      v-model="form.registration_date"
                      :disabled="true"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="注册资本" prop="registered_capital">
                  <el-input
                      clearable
                      :disabled="true"
                      v-model="form.registered_capital"
                      placeholder="请输入注册资本 "/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="24">
                <el-form-item label="注册地址" prop="registration_place_code">
                  <el-row :gutter="15">
                    <el-col :span="4">
                      <el-cascader
                        :disabled="true"
                          ref="refCascader"
                          :options="city"
                          v-model="form.registration_place_code"
                          @change="handleChange"></el-cascader>
                    </el-col>
                    <el-col :span="18">
                      <el-input
                          clearable
                          :disabled="true"
                          v-model="form.detailed_address"
                          placeholder="请输入详细地址 "/>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="法人姓名" prop="legal_person">
                  <el-input
                      clearable
                      :disabled="true"
                      v-model="form.legal_person"
                      placeholder="请输入法人姓名"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="法人身份证" prop="corporate_identity_card">
                  <el-input
                      clearable
                      :disabled="true"
                      v-model="form.corporate_identity_card"
                      placeholder="请输入法人身份证 "/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="法人身份证正面">
                  <div class="upload_bg">
                    <el-upload
                        disabled
                        :action="action"
                        :headers="headers"
                        :on-success="successUpload1"
                        :limit="1"
                        :file-list="fileList1"
                        :class="{hide_box: upload_btn}"
                        :on-change="change"
                        list-type="picture-card"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove">
                      <!--<i class="el-icon-plus"></i>-->
                      <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                      <div style="font-size: 12px;font-weight: 500;color: #ff9b05;">上传身份证正面</div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="法人身份证反面">
                  <div class="upload_bg_shenfenzheng">
                    <el-upload
                      disabled
                        :action="action"
                        :headers="headers"
                        :on-success="successUpload2"
                        :limit="1"
                        :file-list="fileList2"
                        :class="{hide_box: oppositeupload_btn}"
                        :on-change="oppositechange"
                        list-type="picture-card"
                        :on-preview="oppositePreview"
                        :on-remove="oppositehandleRemove">
                      <!--<i class="el-icon-plus"></i>-->
                      <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                    </el-upload>
                    <el-dialog :visible.sync="oppositedialogVisible">
                      <img width="100%" :src="oppositedialogImageUrl" alt="">
                    </el-dialog>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-card>

      <!--基本信息-->
      <el-card shadow="never">
        <div>
          <div style="display: flex;align-items: center;">
            <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
            <span style="font-size: 20px;font-weight: 700;color: #202033;margin-right: 20px;">基本信息</span>
          </div>

          <el-row :gutter="15" style="margin-top: 20px;">
            <el-form-item label="企业logo" label-width="100px">
              <div class="upload_bg_logo">
                <el-upload
                  disabled
                    :action="action"
                    :headers="headers"
                    :on-success="successUpload3"
                    :limit="1"
                    :file-list="fileList3"
                    :class="{hide_box: logoupload_btn}"
                    :on-change="logochange"
                    list-type="picture-card"
                    :on-preview="logoPreview"
                    :on-remove="logohandleRemove">
                  <!--<i class="el-icon-plus"></i>-->
                  <img style="margin-top: 30px;width: 38px;height: 38px;" src="@/assets/images/home/upload_anniu.png" alt="">
                </el-upload>
                <el-dialog :visible.sync="logodialogVisible">
                  <img width="100%" :src="logodialogImageUrl" alt="">
                </el-dialog>
              </div>
            </el-form-item>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="8">
              <el-form-item label="服务商名称" prop="facilitator_name">
                <el-input
                    clearable
                    :disabled="true"
                    v-model="form.facilitator_name"
                    placeholder="请输入服务商名称"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="机构代码">
                <el-input
                    :disabled="true"
                    clearable
                    v-model="form.institution_code"
                    placeholder="请输入机构代码"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="服务商简称" prop="facilitator_abbreviation">
                <el-input
                    clearable
                    :disabled="true"
                    v-model="form.facilitator_abbreviation"
                    placeholder="请输入服务商简称"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="8">
              <el-form-item label="详细地址" prop="profile_detailed_address">
                <el-input
                    clearable
                    :disabled="true"
                    v-model="form.profile_detailed_address"
                    placeholder="请输入详细地址"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="负责人" prop="principal_name">
                <el-input
                    clearable
                    :disabled="true"
                    v-model="form.principal_name"
                    placeholder="请输入负责人"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="负责人电话" prop="principal_phone">
                <el-input
                    clearable
                    :disabled="true"
                    v-model="form.principal_phone"
                    placeholder="请输入负责人电话 "/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="6">
              <el-form-item label="客服电话" prop="service_phone">
                <el-input
                    clearable
                    :disabled="true"
                    v-model="form.service_phone"
                    placeholder="请输入客服电话"/>
              </el-form-item>
            </el-col>
            <!--<el-col :span="6">-->
            <!--  <el-form-item label="电子邮箱" prop="email_address">-->
            <!--    <el-input-->
            <!--        clearable-->
            <!--        v-model="form.email_address"-->
            <!--        placeholder="请输入电子邮箱"/>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :span="6">-->
            <!--  <el-form-item label="企业类型" prop="firm_type">-->
            <!--    <el-select-->
            <!--        clearable-->
            <!--        v-model="form.firm_type"-->
            <!--        placeholder="选择类型"-->
            <!--        class="ele-fluid">-->
            <!--      <el-option-->
            <!--          v-for="(item) in dict.type.enterprise_type"-->
            <!--          :label="item.label"-->
            <!--          :value="item.value"-->
            <!--      />-->
            <!--    </el-select>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :span="6">-->
            <!--  <el-form-item label="外呼业务员" prop="outbound_name">-->
            <!--    <el-input-->
            <!--        clearable-->
            <!--        v-model="form.outbound_name"-->
            <!--        placeholder="请输入外呼业务员"/>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
          </el-row>

          <!--<el-row :gutter="15">-->
          <!--  <el-col :span="6">-->
          <!--    <el-form-item label="调度负责人" prop="dispatch_name">-->
          <!--      <el-input-->
          <!--          clearable-->
          <!--          v-model="form.dispatch_name"-->
          <!--          placeholder="请输入调度负责人"/>-->
          <!--    </el-form-item>-->
          <!--  </el-col>-->
          <!--  <el-col :span="6">-->
          <!--    <el-form-item label="负责人电话" prop="dispatch_phone">-->
          <!--      <el-input-->
          <!--          clearable-->
          <!--          v-model="form.dispatch_phone"-->
          <!--          placeholder="请输入负责人电话"/>-->
          <!--    </el-form-item>-->
          <!--  </el-col>-->
          <!--</el-row>-->

          <el-row :gutter="15">
            <!--<el-col :span="6">-->
            <!--  <el-form-item label="会员等级">-->
            <!--    <el-select-->
            <!--      clearable-->
            <!--      v-model="form.vip_id"-->
            <!--      placeholder="请选择"-->
            <!--      class="ele-fluid">-->
            <!--      <el-option label="全部" value="1"/>-->
            <!--      <el-option label="待认证" value="2"/>-->
            <!--      <el-option label="=已认证" value="3"/>-->
            <!--    </el-select>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :span="18">-->
            <!--  <el-form-item label="闪信内容" prop="flash_letter">-->
            <!--    <el-input-->
            <!--        type="textarea"-->
            <!--        :autosize="{ minRows: 3, maxRows: 5}"-->
            <!--        placeholder="请输入内容"-->
            <!--        v-model="form.flash_letter">-->
            <!--    </el-input>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
          </el-row>
        </div>

        <!--vip会员可见-->
        <div v-if="is_vip == true">
          <div style="margin-top: 30px;">
            <div style="display: flex;align-items: center;">
              <span style="width: 5px;height: 19px;background: #A3A4B2;display: inline-block;margin-right: 10px;"></span>
              <span style="font-size: 20px;font-weight: 700;color: #202033;margin-right: 20px;">VIP会员可见</span>
            </div>
          </div>
          <el-row :gutter="15" style="margin-top: 20px;">
            <el-form-item label="登录页背景图" label-width="100px">
              <div class="upload_bg_bg">
                <el-upload
                  disabled
                    :action="action"
                    :headers="headers"
                    :on-success="successUpload4"
                    :limit="1"
                    :file-list="fileList4"
                    :class="{hide_box: bgupload_btn}"
                    :on-change="bgchange"
                    list-type="picture-card"
                    :on-preview="bgPreview"
                    :on-remove="bghandleRemove">
                  <!--<i class="el-icon-plus"></i>-->
                  <img style="margin-top: 80px;width: 68px;height: 68px;" src="@/assets/images/home/upload_anniu.png" alt="">
                </el-upload>
                <el-dialog :visible.sync="bgdialogVisible">
                  <img width="100%" :src="bgdialogImageUrl" alt="">
                </el-dialog>
              </div>
            </el-form-item>
          </el-row>
          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="版权信息" prop="copyright">
                <el-input
                    clearable
                    v-model="form.copyright"
                    placeholder="请输入版权信息"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="二级域名" prop="sld">
                <el-input
                    clearable
                    v-model="form.sld"
                    placeholder="请输入二级域名"/>
              </el-form-item>
            </el-col>
          </el-row>
        </div>


      </el-card>

    </el-form>

    <!--按钮-->
    <div class="card_bottom">
      <!--<el-button style="width: 200px !important;">取消</el-button>-->
      <!--<el-button type="warning" style="width: 200px !important; margin-left: 20px;" @click="save('form')">提交</el-button>-->
    </div>

  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'

//引入的接口
import {
  Editfacilitators,
  getcityList,
  facilitatorinfo,
} from '@/api/yunli'

import {get_vip} from '@/api/custom'
import Vue from "vue";

export default {
  name: 'index',
  // 支柱道具属性
  props: {
  },
  // 组件生效
  components: {},
  // Data数据
  data() {
    return {
      // 表单数据
      form: {},
      // 验证规则
      rules: {
        company_name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        credit_code:[
          { required: true, message: '请输入信用代码', trigger: 'blur' },
        ],
        registration_date:[
          { required: true, message: '请选择注册日期', trigger: 'change' },
        ],
        registered_capital:[
          { required: true, message: '请输入注册资本', trigger: 'blur' },
        ],
        registration_place_code:[
          { required: true, message: '请选择注册地址', trigger: 'change' },
        ],
        legal_person:[
          { required: true, message: '请输入法人姓名', trigger: 'blur' },
        ],
        corporate_identity_card:[
          { required: true, message: '身份证号不能为空'},
          {pattern:/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            message: '不是合法的身份证号！',trigger: 'blur'}
        ],
        facilitator_name:[
          { required: true, message: '请输入服务商名称', trigger: 'blur' },
        ],
        institution_code:[
          { required: true, message: '请输入机构代码', trigger: 'blur' },
        ],
        facilitator_abbreviation:[
          { required: true, message: '请输入服务商简称', trigger: 'blur' },
        ],
        profile_detailed_address:[
          { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
        principal_name:[
          { required: true, message: '请输入负责人', trigger: 'blur' },
        ],
        principal_phone:[
          { required: true, message: '请输入负责人电话', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        service_phone:[
          { required: true, message: '请输入客户电话', trigger: 'blur' },
        ],
        email_address:[
          { required: true, message: '请输入邮箱', trigger: 'blur' },
        ],
        firm_type:[
          { required: true, message: '请选择企业类型', trigger: 'change' },
        ],
        outbound_name:[
          { required: true, message: '请输入外呼业务员', trigger: 'blur' },
        ],
        dispatch_name:[
          { required: true, message: '请输入调度负责人', trigger: 'blur' },
        ],
        dispatch_phone:[
          { required: true, message: '请输入负责人电话', trigger: 'blur' },
        ],
        flash_letter:[
          { required: true, message: '请输入闪信内容', trigger: 'blur' },
        ],
        copyright:[
          { required: true, message: '请输入版权信息', trigger: 'blur' },
        ],
        sld:[
          { required: true, message: '请输入二级域名', trigger: 'blur' },
        ],
      },

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/capacity',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      //营业执照
      businessLicenseupload_btn:false,
      businessLicensedialogVisible:false,
      businessLicensedialogImageUrl:'',
      fileList:[],

      // 身份证正面
      dialogImageUrl: '',
      dialogVisible: false,
      upload_btn: false, // 是否隐藏上传图片按钮
      fileList1:[],

      //身份证反面
      oppositedialogImageUrl:'',
      oppositedialogVisible:false,
      oppositeupload_btn:false,
      fileList2:[],

      //企业logo
      logoupload_btn:false,
      logodialogVisible:false,
      logodialogImageUrl:'',
      fileList3:[],

      //登录页背景图
      bgupload_btn:false,
      bgdialogVisible:false,
      bgdialogImageUrl:'',
      fileList4:[],

      //城市列表
      city:[],

      // 注册地址
      registration_btn:false,
      registration_place:[],

      // 是否是会员
      is_vip:false,

      //字典
      dict: {
        type:{}
      },

    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 获取详情
    this.getDetails();

    // 获取城市
    this.getCity();

    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '运力字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });
    console.log(this.dict)

  },
  // 方法集合
  methods: {
    // 获取详情信息
    getDetails(){
      facilitatorinfo().then(res => {
        console.log(res);
        this.form = res.data;

        // 营业执照
        if(this.form.business_license != ''){
          let data = {
            url: this.form.business_license
          }
          this.businessLicenseupload_btn = true;
          this.fileList.push(data);
        }

        //正面
        if(this.form.legal_person_identify_front != ''){
          let data1 = {
            url: this.form.legal_person_identify_front
          }
          this.upload_btn = true;
          this.fileList1.push(data1);
        }
        //反面
        if(this.form.legal_person_identify_reverse != ''){
          let data2 = {
            url: this.form.legal_person_identify_reverse
          }
          this.oppositeupload_btn = true;
          this.fileList2.push(data2);
        }

        // 企业logo
        if(this.form.logo != ''){
          let data3 = {
            url: this.form.logo
          }
          this.logoupload_btn = true;
          this.fileList3.push(data3);
        }

        // 登录页背景图
        if(this.form.login_background != ''){
          let data3 = {
            url: this.form.login_background
          }
          this.bgupload_btn = true;
          this.fileList4.push(data3);
        }


      })
    },

    //获取是否为会员接口
    getVip(){
      get_vip().then(res => {
        this.is_vip = res.data.status;
      })
    },

    // 获取城市列表
    getCity(){
      getcityList().then(res => {
        // console.log(res);
        this.city = res.data;
      })
    },

    //注册地址选择
    handleChange(value) {
      console.log(value);
      const areaTextArr = [];
      const arr = this.$refs["refCascader"].getCheckedNodes()[0].pathNodes;
      arr.forEach((i) => {
        areaTextArr.push(i.label);
      });
      // console.log(areaTextArr);
      // console.log(value);
      let area = [];
      let province = value[0] + '|' + areaTextArr[0];
      let city = value[1] + '|' + areaTextArr[1];
      let district = value[2] + '|' + areaTextArr[2];
      area.push(province,city,district);
      console.log(area)

      this.registration_btn = true;
      if(this.registration_btn == true){
        this.registration_place = area;
      }
    },

    // 营业执照
    businessLicensePreview(file){
      this.businessLicensedialogImageUrl = file.url;
      this.businessLicensedialogVisible = true;
    },
    businessLicensehandleRemove(file,fileList){
      console.log(file, fileList);
      this.businessLicenseupload_btn = false;
    },
    businessLicensechange(){
      this.businessLicenseupload_btn = true;
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      this.businessLicensedialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.business_license = file.data.urls;
    },


    //身份证正面
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.upload_btn = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    change(){
      this.upload_btn = true;
    },
    //上传成功
    successUpload1(file,fileList){
      console.log(file);
      console.log(fileList)
      this.dialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.legal_person_identify_front = file.data.urls;
    },

    //身份证反面
    oppositePreview(file){
      this.oppositedialogImageUrl = file.url;
      this.oppositedialogVisible = true;
    },
    oppositehandleRemove(file,fileList){
      this.oppositeupload_btn = false;
    },
    oppositechange(){
      this.oppositeupload_btn = true;
    },
    successUpload2(file,fileList){
      console.log(file);
      console.log(fileList)
      this.oppositedialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.legal_person_identify_reverse = file.data.urls;
    },

    //企业logo
    logoPreview(file){
      this.logodialogImageUrl = file.url;
      this.logodialogVisible = true;
    },
    logohandleRemove(file,fileList){
      this.logoupload_btn = false;
    },
    logochange(){
      this.logoupload_btn = true;
    },
    successUpload3(file,fileList){
      console.log(file);
      console.log(fileList)
      this.logodialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.logo = file.data.urls;
    },

    //登录页背景图
    bgPreview(file){
      this.bgdialogImageUrl = file.url;
      this.bgdialogVisible = true;
    },
    bghandleRemove(file,fileList){
      this.bgupload_btn = false;
    },
    bgchange(){
      this.bgupload_btn = true;
    },
    successUpload4(file,fileList){
      console.log(file);
      console.log(fileList)
      this.bgdialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.login_background = file.data.urls;
    },

    //提交
    save(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.registration_btn == true){
            this.form.registration_place = this.registration_place;
          }
          //编辑
          Editfacilitators(this.id,this.form).then(res => {
            console.log(res)
            if (res.code === 200) {
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          }).catch(e => {
            this.$message.error(e.msg);
          })
        } else {
          return false;
        }
      });
    },

    //返回上级
    returnPage(){
      this.$router.go(-1);
    },

  },
  // 生命周期 - 更新之前
  beforeUpdate() {
  },
  // 生命周期 - 更新之后
  updated() {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {
  },
  // 生命周期 - 挂载之前
  beforeMount() {
  },
  // 生命周期 - 销毁之前
  beforeDestroy() {
  },
  // 生命周期 - 销毁完成
  destroyed() {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated() {
  },
  // 离开的时候触发
  deactivated() {
  }
}
</script>

<style scoped lang="scss">
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
